@import '../../navbar.common.less';

.localeBtn {
  margin-top: 16.5px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0;
  box-shadow: none;
}
.localeBtn {
  margin-top: 0;
}
.localeBtn a:hover {
  color: rgba(0, 0, 0, 0.8);
}
.panellist {
  .collapse {
    margin-left: 34px;
  }
}
.collapse {
  border: none;
}
.collapse > :global.ant-collapse-item {
  border: none;
  background: white;
}
.collapse :global .ant-collapse-content {
  border: none;
}

.collapse :global .ant-collapse-content-box {
  padding: 0;
}
.collapse > :global.ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.collapse > :global.ant-collapse-item > .ant-collapse-header button {
  width: auto;
}
.collapse .panelitem {
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 0 !important;
  margin-left: 34px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
}

.collapse .panelitem .icon {
  color: #696f7f;
  font-size: 20px;
  margin-right: 10px;
}
.imgUrlBox {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 24px;
}
.imageBgStyle {
  background: #f7f7f8;
}

.closeBtn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}

.loginBtn {
  width: 100%;
  height: 36px;
  background-color: #f6f8fb;
  border-radius: 2px;
  padding: 0;
  border: none;
  color: #696f7f;
  font-size: 12px;
  &.onlyLogin {
    background-color: #6f40ee;
    color: #fff;
    margin: 20px 0;
  }
}
.regBtn {
  width: 100%;
  height: 36px !important;
  background-color: #6f40ee;
  border-radius: 2px;
  padding: 0;
  border: none;
  color: #fff;
  font-size: 12px !important;
  margin-top: 24px;
}
.bottomBorder {
  border-bottom: 1px solid #f6f8fb;
  & :global .ant-collapse-item:nth-last-child(1) {
    border-bottom: 1px solid #f6f8fb;
    border-radius: none;
  }
  &:hover {
    border-bottom: 1px solid #f6f8fb;
  }
}
