.avatarContainer{
  display: flex;
}
.iconBtn {
  background-color: transparent;
  border-radius: 50%;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}
