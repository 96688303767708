.radio-checked {
  display: flex !important;
  align-items: center !important;

  :global {
    .ant-radio {
      top: 0;

      .ant-radio-inner {
        background-color: transparent !important;
        border-color: #333444;
        border-width: 2px;
        width: 20px;
        height: 20px;
      }

      .ant-radio-input:focus+.ant-radio-inner {
        border-color: var(--primary-color);
        box-shadow: none;
      }

      &:hover {
        .ant-radio-inner {
          border-color: var(--primary-color);
        }
      }
    }

    .ant-radio-checked {
      .ant-radio-inner {
        background-color: var(--primary-color) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: var(--primary-color);

        &::after {
          position: unset;
          background-color: transparent;
          transform: rotate(-315deg);
          width: 6px;
          height: 9px;
          border: 2px solid #fff;
          border-left: 0;
          border-top: 0;
          border-radius: 0;
          top: auto;
          left: auto;
          margin: 0;
        }
      }
    }
  }
}