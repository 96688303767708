.drawer :global .ant-drawer-body{
  /* padding: 0 15px; */
}
.closeBtn{
  border: none;
  box-shadow: none;
  height: 44px;
  width: 100%;
  text-align: right;
  padding-right: 15px;
}

.inputSearchLine{
  padding: 0 15px;
}

.drawer .inputSearchLine .inputSearch{
  /* margin: 0 15px; */
  border-radius: 32px;
  padding: 8px 12px;
}

.drawerWidth :global .ant-drawer-content-wrapper{
  width: 100vw !important;
}
.DrawerSearchWidth{
  width: 100vw;
}

.DrawerSearch :global .menuTitle,.DrawerSearch :global .menuItem{
  padding: 0 15px;
}
.DrawerSearch{
  height: auto !important;
}