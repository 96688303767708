.tagContainer {
  padding: 0 12px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.tagText {
  // color: #696F7F;
  font-size: 12px;
  margin-left: 8px;
  white-space: nowrap;
}

.icon {
  font-size: 16px;
}

@media screen and (max-width: 960px) {
  .tagContainer {
    padding: 0 9px;
    height: 22px;
    border-radius: 6px;
  }
  .tagText {
    margin-left: 6px;
  }
}
