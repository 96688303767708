.down {
  width: 320px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px 4px 20px;
    .btnWrapper {
    display: flex;
    margin-top: 12px;
  }

  .btnWrapper .btn {
    cursor: pointer;
    width: 90px;
    height: 24px;
    border: 0.5px solid #D9DBDF;
    border-radius: 4.92px;
    font-size: 8px;
    color: #434B5F;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: DINPro;
  }

  .btnWrapper .btn:hover {
    background-image: linear-gradient(rgba(0,0,0,0.08), rgba(0,0,0,0.08));
  }

  .btnWrapper .btn::before {
    content: '';
    background-repeat: no-repeat;
  }

  .btnWrapper .btn:first-child {
    margin-right: 8px;
  }

  .btnWrapper a:first-child .btn::before {
    background-image: url(~@icon/apple.webp);
    width: 9.22px;
    height: 10.87px;
    margin-right: 4.72px;
    background-size: 9.22px 10.87px;
  }

  .btnWrapper a:last-child .btn::before {
    background-image: url(~@icon/google-play.webp);
    width: 9.84px;
    height: 10.91px;
    margin-right: 4.3px;
    background-size: 9.84px 10.91px;
  }

  .btnWrapper a:last-child .btn {
    margin-right: 0;
  }
    .qrcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 4px;
  }

  .qrcode > .img {
    width: 100px;
    height: 100px;
    /* margin-bottom: 4px; */
  }

  .qrcode > .text {
    font-size: 10px;
    color: #696F7F;
    white-space: pre-line;
    text-align: center;
    width: 100%;
    font-family: DINPro;
  }
}

.down .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block .text {
  font-size: 10px;
  color: #8E939F;
}

.block .text {
  font-size: 10px;
  color: #8E939F;
}