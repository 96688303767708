.container {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.icon {
  margin-right: 8px;
}

.interval {
  margin: 0 10px;
  font-size: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.more {
  min-width: 40px;
  margin-left: 8px;
}

.a,.a:hover {
  color: #6F40EE;
  font-size: 16px;
}

.content .item {
  font-family: DINPro-Medium;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  color: #1F2126;
  transition: 0.5s;
  margin-top: 0;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes scroll {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -22px;
  }
}

.itemAnm {
  animation: scroll 0.5s;
  animation-fill-mode: forwards;
}

@media (max-width: 960px) {
  .content .item,.a,.a:hover,.interval {
    font-size: 12px;
  }
  .more {
    min-width: 30px;
  }
}