.player :global button.video-react-big-play-button {
  display: none;
}

.bigPlayBtn {
  z-index: 9;
}

.mask {
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.previewMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.8;
}

.close {
  color: #fff !important;
  font-size: 18px;
}

.close:hover {
  color: #6F40EE !important;
}

.closeWrapper {
  position: absolute;
  right: 30px;
  top: 64px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

.previewPlayer {
  width: fit-content;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.previewPlayer :global .video-react-control-text {
  font-family: DINPro-Medium;
}

.bodyOverflow {
  overflow: hidden;
}

@media (max-width: 960px) {
  .previewPlayer {
    width: 100%;
  }

  .closeWrapper {
    z-index: 1;
  }

  /* .previewMask {
    display: none;
  }
  .closeWrapper {
    display: none;
  } */
}