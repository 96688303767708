
.kycUnauthorized {
  color: #696F7F;
  background-color: #EBEBED;
}
.kycInReview {
  color: #FFC241;
  background-color: #FFF8EC;
}
.kycFailed {
  color: #ED220D;
  background-color: #FDE8E6;
}
.kycSuccess {
  color: #1EAE45;
  background-color: #E8F6EC;
}
.iconSize {
font-size: 16px;
}
@media screen and (max-width: 960px) {
  .iconSize {
    font-size: 12px;
    }
}