.marginMode {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #8E939F;
}

.switch {
  width: 30px !important;
  min-width: 30px !important;
  height: 16.48px !important;
  box-sizing: border-box !important;
  overflow: hidden;
  margin-left: 4px !important;
}

.switch :global(.ant-switch-handle) {
  width: 13px;
  height: 13px;
}

.switch:global(.ant-switch-checked .ant-switch-handle) {
  left: calc(100% - 13px - 2px);
}

.switch:focus {
  box-shadow: none !important;
}