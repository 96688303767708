.bodyOverflow {
  overflow: hidden;
  height: 100vh;
  /* width: 100vw; */
}
.kiki-drawer {
  :global {
    .ant-drawer-header {
      border-bottom: unset !important;
      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }
  }
}
.kiki-drawer-dark {
  color: #fff;
  :global {
    .ant-drawer-content {
      background-color: #201F2D;
    }
    .ant-drawer-title {
      color: #fff;
    }
  }
}
.closeIconContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    height: unset;
    width: unset;
    background-color: unset;
    border-radius: unset;
  }

  .closeIcon {
    font-size: 28px;
    color: #000;
    @media (max-width: 960px) {
      font-size: 24px;
    }
  }
}
.darkCloseIconContainer {
  background-color: #252631;
  @media (max-width: 960px) {
    background-color: unset;
  }
  .darkCloseIcon {
    color: #fff;
    @media (max-width: 960px) {
      font-size: 24px;
    }
  }
}
