@import '../../navbar.common.less';

.closeBtn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}

.btnListpadding{
  padding-top: 0;
}
.noticeBtn
  // .localeBtn
   {
    margin-top: 16.5px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    padding: 0;
    box-shadow: none;
  }
  .noticeBtnmargin{
    margin-top: 0px;
  }
  .logout{
    font-size: 20px;
    color: rgb(105, 111, 127);
    border-top: none;
  }