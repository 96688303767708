.imgContainer {
  position: fixed;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0 2px 24px 0 rgba(200, 201, 204, 0.5);
  // line-height: 52px;
  // text-align: center;
  // font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  right: calc(50% - 669px);
  bottom: 112px;
  z-index: 100;
  &:hover {
    cursor: pointer;
    color: #6f40ee;
  }
}
.rotate {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
    color: #6f40ee;
  }
}

@media (max-width: 1330px) {
  .imgContainer {
    right: 5px;
  }
}
