.modal :global .ant-modal-header{
    height: 48px;
    padding: 18px 21px 0 20px;
    border: none
}
.modal :global .ant-modal-body{
    padding: 48px;
}

.modal :global .ant-modal-footer{
    padding: 0 48px 40px 0;
    border: none; 
}
.modal :global .ant-modal-footer .ant-btn{
    margin: 0;
    width: 180px !important;
    height: 48px !important;
    font-size: 18px !important;
    border-radius: 8px !important;
}
.modal :global .ant-modal-footer .ant-btn:nth-child(1){
    margin-right: 12px;
}

.anticon{
    font-size: 21px;
}


@media (max-width: 960px) {
    .modal :global .ant-modal-header{
        height: 44px;
        font-size: 18px;
    }
    .modal :global .ant-modal-body{
        padding: 36px 15px;
    }
    .modal :global .ant-modal-footer{
        padding: 0 15px 20px; 
        border: none; 
        display: flex;
     }
    .modal :global .ant-modal-footer .ant-btn{
        flex-grow: 1;
        width: auto;
        margin: 0;
        height: 48px;
        font-size: 18px;
        border-radius: 8px !important;
    }
    .modal :global .ant-modal-footer .ant-btn:nth-child(1){
        margin-right: 12px;
    }
}