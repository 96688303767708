.modalContent {
  padding: 48px 20px;
}

.modalContent {
  display: flex;
  justify-content: flex-start;
}

.modalContent .p,
.modalContent .link {
  opacity: 0.8;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.modalContent .p {
  line-height: 28px;
}
.modalContent .link {
  display: inline-block;
  margin-top: 8px;
}
.modalContent .link a {
  color: #6F40EE;
  font-family: DINPro-Medium;
}

.footer {
  display: flex;
  justify-content: center;
}

.ikNow {
  width: 180px !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}
.content {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.content .link {
  margin-top: 0;
}
.radioImage {
  height: 14px;
  width: 14px;
  margin-right: 10px;
  flex-shrink: 0;
}

@media (max-width: 960px) {
  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ikNow {
    width: 100% !important;
  }
}