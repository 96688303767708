.loadingIcon {
  color: #6F40EE !important;
  font-size: 33px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  width: 100%;
  height: 50px;
}

.hidden {
  display: none;
}