@import '@styles/common/baseFn.less';
.helpIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #D9DBDF;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  .extend-click();
}
.helpIcon:hover {
  background: #6F40EE;
}
.helpIcon :global(.anticon) {
  font-size: 8px;
  color: #8E939F;
}
.helpIcon:hover :global(.anticon) {
  color: #FFFFFF;
}