.popover {
  width: 480px;
  padding-top: 0;
}

.popover :global(.ant-popover-arrow) {
  top: -15px;
}

.iconWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helpIcon {
  cursor: pointer;
  margin: -1px 4px 0 2px;
}

.content {
  /* width: 480px; */
  /* padding: 20px; */
}

.p {
  font-weight: 400;
  font-size: 14px;
  color: #8E939F;
  line-height: 22px;
  margin-bottom: 8px;
}

.p:last-child {
  margin-bottom: 0;
}

@media (max-width: 960px) {
  .popover :global(.ant-popover-arrow) {
    top: auto;
    bottom: -15px;
    right: 37px !important;
  }
  .popover {
    width: calc(100% - 30px);
    left: 15px !important;
    padding-bottom: 0;
  }
}