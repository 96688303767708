.iconBtn {
  width: 32px;
  height: 32px;
  background-color: #F6F8FB;
  border-radius: 16px;
  align-items: center;
  margin-right: 20px;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  color: #434B5F;
  &:hover {
    background-color: #F6F8FB;
  }
  &.dark {
    background-color: #353444;
    color: #c4c4c5;
  }
}