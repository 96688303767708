.a {
  display: flex;
  align-items: center;
  .logoImg {
    cursor: pointer;
  }
}

.logo{
  color: #54565a;
}
.darkLogo{
  color: white;
}