.kikiPagination {
    display: flex;
    flex-direction: row;
    justify-content: center; 
}

.prevIconContainer {
    margin-right: 12px;
}

.kikiPagination .prevIconContainer,
.kikiPagination .nextIconContainer {
    padding: 0;
    height: 32px;
    width: 32px;
    line-height: 28px !important;
    background-color: #FFF;
    border: 2px solid #F6F8FB;
    border-radius: 8px;
    color: rgba(67, 75, 96, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.kikiPagination :global .ant-btn {
    box-shadow: none
}

.kikiPagination :global .ant-btn:focus {
    color: rgba(67, 75, 96, 1);
    border: 2px solid #F6F8FB;
}

.kikiPagination :global .ant-btn:hover {
    border: 2px solid rgba(111, 64, 238, 0.1);
    color: rgba(111, 64, 238, 1)
}

.kikiPagination :global .ant-btn[disabled],
.kikiPagination :global .ant-btn[disabled]:hover,
.kikiPagination :global .ant-btn[disabled]:focus,
.kikiPagination :global .ant-btn[disabled]:active {
    border: 2px solid #F6F8FB;
    color: rgba(67, 75, 96, 0.4);
    background-color: #fff;
}

.prevIcon,.nextIcon{
    font-size: 12px;
    width: 12px;
    height: 12px;
}