.modal :global(.ant-modal-header) {
  border-bottom: none;
  padding-left: 20px;
  height: auto;
  padding-top: 32px;
}

.modal :global(.ant-modal-body) {
  padding: 0;
}

.modal :global(.ant-modal-footer) {
  border-top: none;
  padding-top: 0;
  padding-left: 0;
  padding-right: 48px;
  padding-bottom: 40px;
}

.modal :global(.ant-modal-title) {
  font-size: 22px;
  line-height: 30px;
}

.modalBtn {
  width: 180px !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.modalBtn:last-child {
  margin-left: 12px !important;
}

.modalFooter :global(.ant-modal-footer) {
  padding-right: 0;
}

.bodyOverflow {
  overflow: hidden;
  height: 100vh;
  /* width: 100vw; */
}

.closeWrapper {
  display: inline-flex;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  &:hover {
    background: #F6F8FB;
  }
}
.closeIcon {
  font-size: 24px;
}

.modal :global(.ant-modal-close-x) {
  width: 24px;
  height: 24px;
  margin-top: 35px;
  margin-right: 28px;
}

@media (max-width: 960px) {
  .modal :global(.ant-modal-header) {
    padding-left: 15px;
    padding-top: 13px;
  }

  .modal :global(.ant-modal-title) {
    text-align: left;
    font-size: 18px;
    line-height: 25px;
  }

  .modal :global(.ant-modal) {
    width: calc(100vw - 30px) !important;
  }

  .modal :global(.ant-modal-footer) {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .modal :global(.ant-modal-close-x) {
    margin-top: 14px;
    margin-right: 7px;
  }

  .modalBtn {
    flex: 1;
  }

  .modalBtn:last-child {
    margin-left: 15px !important;
  }
}

/** 深色弹窗 */
.darkModal {
  :global {
    .ant-modal-content {
      background-color: #201F2D;
      color: #fff;
    }

    .ant-modal-header {
      background-color: #201F2D;
    }

    .ant-modal-title {
      color: #fff;
    }
  }
}

.darkCloseIcon {
  color: #fff !important;
}

.darkCancelBtn {
  background-color: #333444 !important;
  color: #fff !important;
}