.h5menuSwitch {
  display: flex;
  align-items: center;
  .menuBtn {
    background-color: transparent;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    box-shadow: none;
  }
}