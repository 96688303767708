  .drawerHeader {
    position: fixed;
    height: 44px;
    width: 100%;
    z-index: 10;
    background-color: #FFFFFF;
    .closeBtn {
      position: absolute;
      top: 15px;
      left: 260px;
      z-index: 10;
    }
  }
  .btnList {
    padding: 44px 15px 0;
  }
  .btnText {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    margin-left: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
  }
  .btnText:hover {
    color: rgba(0, 0, 0, 0.8);
  }

.userInfo {
  display: flex;
  align-items: center;
  &.h5UserInfo {
    padding-top: 44px;
  }
}
.userInfoAvatarContainer {
  margin-right: 16px;
  margin-left: 20px;

}
.userInfoAvatar {
  border-radius: 50%;
}
.userInfoName {
  font-size: 20px;
  line-height: 28px;
  width: 192px; 
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 6px;
}
.userInfoNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.userInfoLabel {
  display: flex;
  align-items: center;
}
.language{
  justify-content: space-between;
  padding-right: 15px;
  color: #6F40EE;
}
.language .selectIcon{
  display: block;
  color: #6F40EE;
}
.selectIcon{
  display: none;
}
