.body {
    position: relative;
}
.container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.overflow {
    overflow: hidden;
}