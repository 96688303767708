
/**盒子宽高*/
.size(@w, @h) { width: @w; height: @h; }


// 统一盒模型
.border-box() {
  *, *:after, *:before { 
    -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; 
  }
}


// 文字图片居中
.center(text-x) { text-align: center; }
.center(text-y) { display: table-cell; vertical-align: middle; }



// 块级元素水平居中
.center(auto-x) { display: block; margin-left: 0 auto; }



// 居中, 不确定尺寸, 不兼容 IE6
.center(unknown) { position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; }
.center(unknown-x) { position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto; }
.center(unknown-y) { position: absolute; top: 0; bottom: 0; margin-top: auto; margin-bottom: auto; }




// 居中, CSS3 平移方式, 兼容性不行
.center(translate) { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
 
// 居中, Flex 方式, 兼容性不行
.center(flex) { display: flex; align-items: center; justify-content: center; }


// 遮罩层, 全屏遮罩、区域遮罩
.over-screen(fixed) { .fixed(); top: 0; left: 0; right: 0; bottom: 0; }
.over-screen(absolute) { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }



// 扩展点击区域
.extend-click() {
  position: relative;
  &:before { content: ''; position: absolute; top: -10px; left: -10px; right: -10px; bottom: -10px; }
}


// 定宽居中页面布局
.layout-page(@width: 1200px) { width: @width; margin: 0 auto; }



// 字体大小
.fz(@fz) { font-size: @fz; }
 
// 字体大小与行高
.fz(@fz, @lh) { font-size: @fz; line-height: @lh; }
 
// 字体大小、行高、高度
.fz(@fz, @h, @lh) { font-size: @fz; height: @h; line-height: @lh; }
 
// 行高与高度
.lh(@h, @lh) { height: @h; line-height: @lh; }

//字体大小、行高、颜色、粗细
.baseFont( @lh, @color, @bold: 500, @fz: 14px){
  font-size: @fz;
  line-height: @lh;
  color: @color;
  font-weight: @bold;
}
// 字体颜色, 包括链接与非链接
.color(@color) { color: @color;}
 
// 字体颜色 + 自身 Hover
.color(@color, @hoverColor) {
  color: @color;
  &:hover { 
    color: @hoverColor; 
  }
}



// 字体颜色 + 链接 Hover
.color(@color, @aColor, @hoverColor) {
  color: @color;
  a {
    color: @aColor;
    &:hover { 
      color: @hoverColor; 
    }
  }
}  



// 禁止换行, 文本溢出省略号显示 (一行)
.ellipsis() {
  white-space: normal; 
  word-wrap: break-word; 
  word-break: break-all;
  text-overflow:ellipsis;
  overflow:hidden;
}  



// 文本溢出省略号显示 (多行)
// 只支持 webkit 浏览器, 解决方案：高度 = 行高*行数
.ellipsis-multiply(@n: 3) {
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @n; 
  word-break: break-all;
  text-overflow:ellipsis;
  overflow: hidden;
}



// 文字透明
.transparent-text() { font: 0/0 serif; text-shadow: none; color: transparent; }



// 文字外发光/阴影效果
.glow-text(@r: 10px, @color: gold) { text-shadow: 0 0 @r @color; }



// 用 max-width 来防止图片撑破容器
.max-img() { display: block; max-width: 100%; height: auto; }



// 滤镜: 将彩色照片显示为黑白照片
.grayscale() {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}



// 链接默认无下划线，hover后有下划线的样式
.hover-link() {
  text-decoration: none;
  &:hover { 
    text-decoration: underline; 
  }
}


// 盒子阴影
// box-shadow: 水平阴影的位置, 垂直阴影的位置, 模糊距离, 阴影的大小, 阴影的颜色, 阴影开始方向（默认是从里往外，设置inset就是从外往里）;
// box-shadow: h-shadow v-shadow blur spread color inset;
.box-shadow(@h-shadow: 0, @v-shadow: 2px, @blur: 24px, @spread:0, @color: #C8C9CC, @inset: 0) {
  box-shadow: @h-shadow @v-shadow @blur @spread @color @inset;
}
  



// 清除浮动
.clearfix() {
  *zoom: 1;
  &:after {
    display: block;
    clear: both; content: '';
    visibility: hidden; height: 0; 
  }
}
.clearfix(table) {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
    clear: both; 
  }
}
  
   
// 禁止文本被选择
.user-select() { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
 
// 隐藏鼠标手势
.hide-cursor() { cursor: none !important; }
 
// 鼠标禁用样式，但仍然可以触发事件
.disabled() { cursor: not-allowed; }



// 禁用元素事件
// 1. 阻止任何点击动作的执行
// 2. 使链接显示为默认光标(cursor:default)
// 3. 阻止触发hover和active状态
// 4. 阻止JavaScript点击事件的触发
.pointer-events() { pointer-events: none; }