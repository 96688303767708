.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  .childrenMenuItemClassName {
    padding: 0;
    margin-top: 0;
    & > div:first-child {
      height: 40px;
    }
  }
}
.headerRight .menuSearch {
  border: none !important;
  /* top: 3px; */
}
.menuSearch {
  box-shadow: none;
  padding-right: 20px;
  top: 1px;
}
.grayBtn,
.grayBtn:hover {
  background-color: #f6f8fb;
  border-radius: 16px;
  border: none;
  color: #696f7f;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.grayBtn .btnArrow {
  margin-left: 4px;
}
.login:hover,
.register:hover {
  transform: scale(1.08);
}

.login,
.register {
  border-radius: 16px !important;
  padding: 6px 23px;
}
.blackBtn,
.blackBtn:hover {
  background-color: #1c1e23;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  padding: 6px 23px;
}
.iconBtn {
  width: 32px;
  height: 32px;
  background-color: #f6f8fb;
  border-radius: 16px;
  align-items: center;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  color: #434b5f;
}
.iconBtn:hover {
  background-color: #f6f8fb;
}
.avatarContainer {
  display: flex;
}
@media (max-width: 1000px) {
  .headerRight :global .ant-btn {
    margin-right: 10px !important;
  }
}
@media (max-width: 970px) {
  .headerRight :global .ant-btn {
    margin-right: 5px !important;
  }
}
